<template>
	<ECommerceCardWrapper
		class="shopping-cart-card"
		padding="16px"
		staticComponent
	>
		<div class="shopping-cart-card-main">
			<ShoppingCartCardProducts
				v-for="(product, i) in cardItems"
				:key="i"
				:product="product"
			/>
			<ShoppingCartCardSubscriptionItem
				v-if="subscriptionItems"
				:subscriptionItems="subscriptionItems"
			/>
		</div>
		<template v-if="bottomBanner.showBanner">
			<div class="shopping-cart-card-banner">
				<h3>{{ bottomBanner.text }}</h3>
			</div>
		</template>
	</ECommerceCardWrapper>
</template>
<script>
import { mapGetters } from "vuex";
import { formatPrice } from "~/helpers/formatting/price";
export default defineNuxtComponent({
	name: "ShoppingCartCard",

	props: {
		// Use custom validator to check that at least one of the props is not null
		cardItems: {
			type: Array,
			validator: function (value) {
				return value !== null || this.subscriptionItems !== null;
			},
			default: null,
		},
		subscriptionItems: {
			type: Array,
			validator: function (value) {
				return value !== null || this.cardItems !== null;
			},
			default: null,
		},
	},

	computed: {
		...mapGetters("shoppingCart", ["isVatExempt", "eligibleMemberPrice"]),
		bottomBanner() {
			const memberPriceText = `Pris for telenor-kunder: ${formatPrice.oneTime(this.eligibleMemberPrice?.memberPrice, {
				round: true,
			})}`;
			return {
				showBanner:
					this.cardItems?.some((item) => item?.metadata?.memberPrice?.prices?.withVat?.discount > 0) ||
					this.totalDiscount > 0,
				text: this.cardItems?.some((item) => item?.metadata?.memberPrice?.prices?.withVat?.discount > 0)
					? memberPriceText
					: `Spar ${formatPrice.oneTime(this.totalDiscount, { round: true })}`,
			};
		},
		totalDiscount() {
			let totalDiscount = 0;
			this.cardItems?.forEach((item) => {
				if (!item.metadata?.salePrice || item.metadata?.prices?.discountedDownPaymentPrice) return;
				if (this.isVatExempt) {
					totalDiscount += item.metadata?.priceWithoutVat - item.metadata?.salePriceWithoutVat;
				} else {
					totalDiscount += item.metadata?.price - item.metadata?.salePrice;
				}
			});
			return totalDiscount;
		},
	},
});
</script>
<style lang="scss" scoped>
.shopping-cart-card {
	margin: $spacing-l 0;
}

.shopping-cart-card-main {
	margin-bottom: (-$spacing-l);
	width: stretch;
}

.shopping-cart-card-main > * {
	margin-bottom: $spacing-l;
}

.shopping-cart-card-banner {
	width: stretch;
	height: 52px;
	display: flex;
	align-items: center;
	background-color: $color-primary-superlight;
	margin: $spacing-m (-$spacing-m) (-$spacing-m) (-$spacing-m);
	border-radius: 0 0 $spacing-s $spacing-s;

	h3 {
		margin-left: $spacing-m;

		@include font-text-bold-xs;
	}
}
</style>
